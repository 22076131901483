import React from 'react'
import { Link } from 'gatsby'

class SelectServizi extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  handleClick = e => {
    e.preventDefault()
    let toggle = !this.state.isOpen
    this.setState({
      isOpen: toggle,
    })
  }

  render() {
    const { isOpen } = this.state
    const toggle = isOpen ? 'open' : ''

    return (
      <div className={'divselect ' + toggle}>
        <button onClick={this.handleClick} className={'divselect__default'}>
          Altri animali <i />
        </button>
        <ul className="divselect__list">
          <li className="divselect__item">
            <Link to="/piccoli-animali/">Clinica dei piccoli animali</Link>
          </li>
          <li className="divselect__item">
            <Link to="/esotici-e-non-convenzionali">
              Esotici e non convenzionali
            </Link>
          </li>
          <li className="divselect__item">
            <Link to="/Clinica equina">Clinica equina</Link>
          </li>
          <li className="divselect__item">
            <Link to="/ruminanti-e-suini">Ruminanti e suini</Link>
          </li>
        </ul>
      </div>
    )
  }
}

export default SelectServizi
